import { ArrowRight } from "lucide-react";
import videoBg from "../../assets/videos/bg-hero.mp4";

const Hero = () => {
  const heroText = {
    title: "La transformación digital que necesita tu empresa",
    description:
      "Diseñamos y desarrollamos estrategias y soluciones digitales que se adaptan a las necesidades de tu empresa",
    cta: "Comienza ahora",
  };

  return (
    <section className="relative w-full h-[80vh]">
      {/* Video Background */}
      <div className="absolute inset-0 flex justify-center items-center overflow-hidden">
        <video className="w-full h-full object-cover" src={videoBg} autoPlay loop muted playsInline></video>

        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-primary/10"></div>
      </div>

      {/* Content container - Centered vertically */}
      <div className="relative z-10 flex items-center justify-start h-full lg:px-16">
        <div className="pl-5 md:p-10 rounded-lg max-w-2xl space-y-6">
          {/* Title with enhanced styling */}
          <h1 className="text-4xl md:text-5xl font-bold leading-tight text-white">{heroText.title}</h1>

          {/* Description */}
          <p className="text-lg md:text-xl text-white leading-relaxed">{heroText.description}</p>

          {/* CTA buttons - Commented out */}
          {/*
          <div className="flex flex-col sm:flex-row gap-4 pt-4">
            <a
              href="#contact"
              className="inline-flex items-center justify-center px-8 py-4 bg-primary border border-white hover:bg-primary/90 text-white rounded-full text-lg font-medium transition-all duration-300 group"
            >
              {heroText.cta}
              <ArrowRight className="ml-2 w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1" />
            </a>
          </div>
          */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
