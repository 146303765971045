import React from "react";
import {
  CompassIcon,
  CodeIcon,
  BrainCircuitIcon,
  MegaphoneIcon,
  ArrowRight,
} from "lucide-react";

const ServicesOverview = () => {
  const sectionHeading = {
    title: "Nuestros Servicios",
    subtitle: "Sorprende a tus clientes. Supera a tu competencia.",
  };

  const features = [
    {
      id: 1,
      icon: <CompassIcon className="w-10 h-10 text-primary/70" />,
      title: "Consultoría de Transformación Digital",
      subtitle: "¿Buscas digitalizar tu empresa para llevarla al siguiente nivel?",
      text: "Analizamos tu situación actual y diseñamos un plan de acción a medida para optimizar procesos, integrar nuevas tecnologías y afianzar una cultura digital en tu equipo.",
      cta: "Empieza tu transformación",
      ctaLink: "/transformacion-digital",
      image: require("../../assets/imgs/5.png"),
    },
    {
      id: 2,
      icon: <BrainCircuitIcon className="w-10 h-10 text-primary/70" />,
      title: "IA, Automatización de procesos y Análisis de Datos",
      subtitle: "¿Quieres tomar decisiones más acertadas y ahorrar tiempo?",
      text: "Te ayudamos a implementar inteligencia artificial, automatizar tareas repetitivas y explotar tus datos para impulsar la eficiencia y la rentabilidad de tu negocio.",
      cta: "Explora el potencial de la IA",
      ctaLink: "/soluciones-ia",
      image: require("../../assets/imgs/6.png"),
    },
    {
      id: 3,
      icon: <CodeIcon className="w-10 h-10 text-primary/70" />,
      title: "Desarrollo de páginas web y aplicaciones personalizadas",
      subtitle: "¿Necesitas una página web que conecte con tu público?",
      text: "Creamos páginas web y aplicaciones modernas, seguras y escalables para fortalecer tu presencia online y mejorar la experiencia de tus usuarios.",
      cta: "Descubre nuestros desarrollos",
      ctaLink: "/consultoria",
      image: require("../../assets/imgs/7.png"),
    },
    {
      id: 4,
      icon: <MegaphoneIcon className="w-10 h-10 text-primary/70" />,
      title: "Marketing Digital y Posicionamiento online",
      subtitle: "¿Buscas destacar tu empresa en un mercado saturado?",
      text: "Diseñamos estrategias de marketing digital para que conectes con tu audiencia ideal. Desde redes sociales y email marketing hasta SEO y campañas de pago, te ayudamos a maximizar tus resultados.",
      cta: "Potencia tu alcance",
      ctaLink: "/optimizacion",
      image: require("../../assets/imgs/8.png"),
    },
  ];

  return (
    <section id="our-services" className="relative py-20 lg:py-28 px-4 md:px-8 bg-background text-neutralDark">
      <div className="max-w-7xl mx-auto">
        {/* Section header */}
        <div className="text-center mb-20">
          <h2 className="text-3xl md:text-4xl font-bold text-primary">{sectionHeading.title}</h2>
          <p className="text-xl text-neutralDark mt-4 max-w-2xl mx-auto">{sectionHeading.subtitle}</p>
          <div className="mx-auto mt-8 w-16 h-[3px] bg-secondary rounded" />
        </div>

        {/* Features container */}
        <div className="space-y-12 lg:space-y-8">
          {features.map((feature, index) => (
            <div key={feature.id} className="bg-neutralLight/50 rounded-2xl overflow-hidden">
              <div className={`flex flex-col lg:flex-row ${index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"}`}>
                {/* Image */}
                <div className="w-full lg:w-1/2 flex items-center">
                  <div className="h-48 md:h-64 lg:h-auto lg:p-12 w-full">
                    <div className="w-full h-full flex items-center justify-center">
                      <img
                        src={feature.image}
                        alt={feature.title}
                        className="w-full h-full object-cover lg:object-contain"
                      />
                    </div>
                  </div>
                </div>

                {/* Content */}
                <div className="w-full lg:w-1/2 py-8 px-4 md:px-12 lg:p-16 flex flex-col justify-center">
                  <div className="inline-flex items-center bg-primaryLight/30 rounded-md px-4 py-3 mb-6">
                    {feature.icon}
                    <p className="ml-3 text-lg text-primary/70 italic leading-tight font-semibold">
                      {feature.subtitle}
                    </p>
                  </div>

                  <h3 className="text-3xl lg:text-4xl font-bold text-primary mb-6">{feature.title}</h3>

                  <p className="text-lg text-neutralDark/80 leading-relaxed mb-6">{feature.text}</p>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesOverview;
