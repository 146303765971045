import ContactForm from "./ContactForm";
import { Mail, Phone, MapPin } from "lucide-react";

const Contact = () => {
  const sectionHeading = {
    title: "¿Hablamos?",
    subtitle:
      "Cuéntanos el proyecto que te gustaría hacer y nosotros te ofrecemos una primera reunión totalmente gratuita.",
  };

  const contactDetails = [
    {
      icon: <Mail className="w-8 h-8 text-secondary" />,
      text: "contacto@oakmanvs.com",
      href: "mailto:contacto@oakmanvs.com",
    },
    {
      icon: <Phone className="w-8 h-8 text-secondary" />,
      text: "+34 696 747 490",
      href: "tel:+34696747490",
    },
    {
      icon: <MapPin className="w-12 md:w-8 h-8 text-secondary" />,
      text: "Calle Fernández de la Hoz 48, 28010, Madrid, España",
      href: null, // Sin enlace
    },
  ];

  return (
    <section id="contact" className="grid grid-cols-1 lg:grid-cols-2">
      {/* Left Column - Contact Info */}
      <div className="bg-primary/90 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-16 2xl:px-40 space-y-8">
          <h2 className="text-3xl lg:text-4xl font-bold">{sectionHeading.title}</h2>
          <div className="mb-8 w-16 h-[3px] bg-secondary rounded" />
          <p className="text-lg">{sectionHeading.subtitle}</p>
          <div className="space-y-6">
            {contactDetails.map((detail, index) => (
              <div key={index} className="flex items-center gap-4 text-lg">
                {detail.icon}
                {detail.href ? (
                  <a href={detail.href} className="hover:text-secondary transition-colors">
                    {detail.text}
                  </a>
                ) : (
                  <span>{detail.text}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Right Column - Form with Background Image */}
      <div
        className="relative bg-cover bg-center"
        style={{
          backgroundImage: `url(${require("../../assets/imgs/contact-bg.png")})`,
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0"></div>

        {/* Form */}
        <div className="relative z-10 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
            <div className="bg-white rounded-xl shadow-lg p-8 sm:p-12 lg:p-16">
              <h3 className="text-2xl font-semibold mb-6 text-primary">Envíanos un mensaje</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
