import Hero from '../components/LandingPage/Hero';
import ServicesOverview from '../components/LandingPage/ServicesOverview';
import CredentialsBanner from '../components/LandingPage/CredentialsBanner';
import SuccessCases from '../components/LandingPage/SuccessCases';
import TrainingBanner from '../components/LandingPage/TrainingBanner';
import AboutUs from '../components/LandingPage/AboutUs';
import Contact from '../components/LandingPage/Contact';
import BlogSection from '../components/LandingPage/BlogSection.jsx';

const LandingPage = () => {
  return (
    <div>
      <Hero />
      <ServicesOverview />
      <CredentialsBanner />
      <TrainingBanner />
      <AboutUs />
      <Contact />
    </div>
  );
};

export default LandingPage;