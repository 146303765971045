import BancoSantander from "../../assets/logos/cr-banco-santander.webp";
import EuropeanCommission from "../../assets/logos/cr-european-commission.webp";
import Amazon from "../../assets/logos/cr-amazon.webp";
import BBVA from "../../assets/logos/cr-bbva.webp";
import Alibaba from "../../assets/logos/cr-alibaba.webp";
import GrupoMelia from "../../assets/logos/cr-melia-hotels.webp";

const CredentialsBanner = () => {
  const sectionHeading = {
    title: "No son rumores.",
    subtitle: "Es una década de experiencia.",
  };

  const companies = [
    {
      id: 1,
      name: "Banco Santander",
      logo: BancoSantander
    },
    {
      id: 2,
      name: "European Commission",
      logo: EuropeanCommission
    },
    {
      id: 3,
      name: "Amazon",
      logo: Amazon
    },
    {
      id: 4,
      name: "BBVA",
      logo: BBVA
    },
    {
      id: 5,
      name: "Alibaba",
      logo: Alibaba
    },
    {
      id: 6,
      name: "Grupo Meliá",
      logo: GrupoMelia
    }
  ];

  return (
    <section className="relative py-14 px-4 md:px-8 bg-gradient-to-r from-primaryLight to-secondary/10">
      <div className="max-w-7xl mx-auto">
        <div className="mb-12">
          <p className="text-3xl font-bold text-primary">
            {sectionHeading.title}
          </p>
          <p className="text-4xl font-semibold text-primary/70">
            {sectionHeading.subtitle}
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-6 gap-8 md:gap-12 items-center justify-items-center">
          {companies.map((company) => (
            <div 
              key={company.id}
              className="w-50 h-30 flex items-center justify-center"
            >
              <img
                src={company.logo}
                alt={`Logo de ${company.name}`}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CredentialsBanner;