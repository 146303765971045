import React from "react";
import { Linkedin, Mail } from "lucide-react";
import profileImage from "../../assets/imgs/me.jpg";

const AboutUs = () => {
  const person = {
    name: "Alex Carballo",
    title: "Managing Partner",
    linkedIn: "https://www.linkedin.com/in/acarballoalvarez/",
    email: "contacto@oakman.com",
    imageSrc: profileImage,
  };

  const sectionTitle = "Sobre nosotros";

  const content = `En Oakman, nos especializamos en inteligencia artificial y el desarrollo de aplicaciones web de última generación, combinando innovación tecnológica con un enfoque centrado en las necesidades de nuestros clientes. Con más de 10 años de experiencia en el sector, hemos logrado posicionarnos como un socio de referencia para empresas tanto nacionales como internacionales, así como para instituciones públicas que buscan soluciones tecnológicas de vanguardia.

Desde nuestros inicios, adoptamos una filosofía AI First, lo que significa que colocamos la inteligencia artificial en el núcleo de cada proyecto que desarrollamos. Este enfoque nos permite diseñar herramientas y plataformas que no solo resuelven problemas actuales, sino que también anticipan las necesidades futuras de nuestros clientes.`;

  return (
    <section id="about-us" className="relative py-20 lg:py-28 px-4 md:px-8 bg-background text-neutralDark">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
        {/* Text and about us section */}
        <div className="order-1 md:order-2 flex flex-col justify-start">
          <h2 className="text-3xl font-bold text-primary">{sectionTitle}</h2>
          <div className="my-8 w-16 h-[3px] bg-secondary rounded" />
          <p className="text-lg leading-relaxed text-neutralDark/80 whitespace-pre-wrap">{content}</p>
        </div>

        {/* Personal information and image */}
        <div className="order-2 md:order-1 text-center">
          <div className="w-72 h-72 mx-auto rounded-full overflow-hidden shadow-lg">
            <img src={person.imageSrc} alt={person.name} className="object-cover w-full h-full" />
          </div>
          <h3 className="mt-4 text-xl font-bold text-primary">{person.name}</h3>
          <p className="text-primary/70 font-semibold">{person.title}</p>
          <div className="flex justify-center gap-4 mt-2">
            <a
              href={person.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-primary underline hover:text-secondaryLight transition"
            >
              <Linkedin className="w-5 h-5 mr-1" />
            </a>
            <a
              href={`mailto:${person.email}`}
              className="flex items-center text-primary underline hover:text-secondaryLight transition"
            >
              <Mail className="w-5 h-5 mr-1" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
